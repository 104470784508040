<template>
  <table class="table" :class="{ striped: striped }">
    <thead>
      <tr>
        <slot name="prependHeader">
          <th v-if="selectable" :style="{ width: '2%' }">
            <input
              type="checkbox"
              v-model="isBulkAction"
              :disabled="!data.length"
              :indeterminate.prop="selectionIsIndeterminate"
              @change="handleBulkSelectRows"
            />
          </th>
        </slot>
        <TableHeader
          v-for="(header, index) in headers"
          :key="index"
          :header="header"
          @sort="handleSort"
        />
        <slot name="appendHeader">
          <th :style="{ width: '4%' }"></th>
        </slot>
      </tr>
    </thead>
    <tbody>
      <TableRow
        v-for="(row, index) in data"
        :key="index"
        :id="row[primaryKey]"
        :row="row"
        :columns="columns"
        :selected="isRowSelected(row)"
        @select="handleSelectRow"
      >
        <template #prependColumn>
          <slot
            name="prependColumn"
            :index="index"
            :row="row"
            :columns="columns"
          />
        </template>
        <template #appendColumn>
          <slot
            name="appendColumn"
            :index="index"
            :row="row"
            :columns="columns"
          />
        </template>
      </TableRow>
    </tbody>
  </table>
</template>

<script>
import { ref, computed, provide, onMounted, onUnmounted } from 'vue';
import TableHeader from './TableHeader.vue';
import TableRow from './TableRow.vue';
import { useTable } from '@/helpers/composables';

export default {
  components: {
    TableHeader,
    TableRow,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    primaryKey: {
      type: String,
      default: () => 'id',
    },
    selectable: {
      type: Boolean,
    },
    striped: {
      type: Boolean,
    },
    headerClasses: {
      type: String,
      default: () => '',
    },
    rowClasses: {
      type: String,
      default: () => '',
    },
    columnClasses: {
      type: String,
      default: () => '',
    },
  },
  emits: ['select'],
  setup(props, { emit }) {
    const selected = ref([]);
    const isBulkAction = ref(false);
    const sort = ref();

    provide('selectable', props.selectable);
    provide('headerClasses', props.headerClasses);
    provide('rowClasses', props.rowClasses);
    provide('columnClasses', props.columnClasses);
    provide(
      'sort',
      computed(() => sort.value)
    );

    const selectionIsIndeterminate = computed(() => {
      return (
        !isBulkAction.value &&
        selected.value.length &&
        selected.value.length !== props.data.length
      );
    });

    const { event } = useTable();

    onMounted(() => {
      event.on('resetSelected', () => {
        resetSelected();
      });
    });

    onUnmounted(() => {
      event.off('resetSelected');
    });

    function toggleBulkAction() {
      isBulkAction.value = selected.value.length === props.data.length;
    }

    function handleBulkSelectRows() {
      if (isBulkAction.value) {
        selected.value = props.data.map((row) => row.id);
      } else {
        selected.value = [];
      }
      emit('select', { selected });
    }

    function handleSelectRow({ id, row }) {
      toggleSelecedtRow(id);
      toggleBulkAction();
      emit('select', { id, row, selected });
    }

    function toggleSelecedtRow(id) {
      if (!selected.value.includes(id)) {
        selectRow(id);
      } else {
        deselectRow(id);
      }
    }

    function selectRow(id) {
      selected.value.push(id);
    }

    function deselectRow(id) {
      const index = selected.value.indexOf(id);
      if (index !== -1) selected.value.splice(index, 1);
    }

    function isRowSelected({ id }) {
      return !!selected.value.find((row) => row === id);
    }

    function resetSelected() {
      isBulkAction.value = false;
      selected.value = [];
      emit('select', { selected });
    }

    function handleSort(item) {
      sort.value = item;
    }

    return {
      selectionIsIndeterminate,
      handleBulkSelectRows,
      handleSelectRow,
      isRowSelected,
      isBulkAction,
      handleSort,
    };
  },
};
</script>

<style lang="postcss">
.table {
  @apply table-fixed
    w-full;
}
.table thead th {
  @apply border-b border-gray-300;
}
.table.striped thead th {
  @apply bg-gray-50 border-none;
}
.table tbody tr:not(:last-child) {
  @apply border-b border-gray-300;
}
.table.striped tbody tr {
  @apply bg-white
    text-left;
}
.table.striped tbody tr:not(:last-child) {
  @apply border-none;
}
.table.striped tbody tr:nth-child(even) {
  @apply bg-gray-50;
}
.table.striped tbody tr.selected {
  @apply bg-blue-50;
}
.table.striped tbody tr.selected:nth-child(even) {
  @apply bg-blue-100;
}
</style>
