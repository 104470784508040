<template>
  <tr @click="handleSelect" :class="[rowClasses, { selected: selected }]">
    <slot name="prependColumn">
      <td v-if="selectable"><input type="checkbox" :checked="selected" /></td>
    </slot>
    <TableData
      v-for="(column, index) in columns"
      :key="index"
      :column="column"
      :row="row"
    />
    <slot name="appendColumn" />
  </tr>
</template>

<script>
import { inject } from 'vue';
import TableData from './TableData.vue';

export default {
  components: {
    TableData,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    columns: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      default: () => null,
    },
    selected: {
      type: Boolean,
    },
  },
  emits: ['select'],
  setup(props, { emit }) {
    const selectable = inject('selectable');
    const rowClasses = inject('rowClasses');

    function handleSelect() {
      selectable && emit('select', { id: props.id, row: props.row });
    }

    return {
      selectable,
      rowClasses,
      handleSelect,
    };
  },
};
</script>
