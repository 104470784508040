<template>
  <div
    class="search-dropdown-item"
    :class="{ active: active }"
    @click="handleSelect"
  >
    <span>{{ item[displayKey] }}</span>
  </div>
</template>

<script>
import { watch, ref } from 'vue';

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    displayKey: {
      type: String,
      default: () => 'id',
      required: true,
    },
    index: {
      type: Number,
      default: () => null,
      required: true,
    },
    cursorIndex: {
      type: Number,
      default: () => null,
      required: true,
    },
  },
  emits: ['select'],
  setup(props, { emit }) {
    const active = ref(false);

    watch(
      () => props.cursorIndex,
      (newVal) => {
        active.value = props.index === newVal;
      }
    );

    function handleSelect() {
      emit('select', {
        value: props.item[props.displayKey],
        item: props.item,
        index: props.index,
      });
    }

    return {
      handleSelect,
      active,
    };
  },
};
</script>

<style lang="postcss" scoped>
.search-dropdown-item {
  @apply cursor-pointer
    bg-white
    hover:bg-gray-100
    transition-all
    duration-300
    ease-out
    px-3
    py-2;
}
.search-dropdown-item.active {
  @apply bg-yellow-500;
}
</style>
