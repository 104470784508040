const invoiceStatus = {
  IN_PROGRESS: 'in_progress',
  AWAITING_APPROVAL_CSM: 'awaiting_approval_csm',
  AWAITING_APPROVAL_AP: 'awaiting_approval_ap',
  AWAITING_REVISION: 'rejected',
  APPROVED: 'approved',
  INVOICE_ISSUED: 'issued',
  OFFLINE: 'offline',
};
const invoiceStatusColorCoding = {
  'In Progress': 'gray',
  'Awaiting Approval - CSM': 'primary',
  'Awaiting Approval - AP': 'brown',
  'Awaiting Revision': 'danger',
  Approved: 'success',
  'Invoice Issued': 'blue',
  Offline: 'gray',
};
const notificationType = {
  AWAITING_APPROVAL_AP: 'awaiting_accounting_approval',
  AWAITING_APPROVAL_CSM: 'awaiting_director_approval',
  AWAITING_REVISION: 'needs_revision',
  SEND: 'approved',
  INVOICE_ISSUED: 'sent_to_client',
};

const roleId = {
  CLIENT: 6,
};

const invoiceDocumentType = {
  PREVIOUS_INVOICE: '1',
  ENTRIES_BY_JOB: '2',
  JOB_COST_TO_DATE: '3',
  BUDGET_REPORT: '4',
  OTHER_DOCUMENTS: '5',
};

export default {
  INVOICE_STATUS: invoiceStatus,
  INVOICE_STATUS_COLOR_CODING: invoiceStatusColorCoding,
  NOTIFICATION_TYPE: notificationType,
  USER_ROLE_ID: roleId,
  INVOICE_DOCUMENT_TYPE: invoiceDocumentType,
};
