<template>
  <th
    v-bind="bindProps"
    :class="[headerClasses, { 'cursor-pointer': header.sortAction }]"
    @click="handleSort"
  >
    <span>{{ header.label }}</span>
    <span v-if="header.sortAction" class="inline-block ml-3">
      <button
        class="inline-block sort-button"
        :class="{ active: order === 'desc' }"
      >
        <down-arrow-icon />
      </button>
      <button
        class="inline-block ml-1 sort-button"
        :class="{ active: order === 'asc' }"
      >
        <up-arrow-icon />
      </button>
    </span>
  </th>
</template>

<script>
import { ref, computed, inject, watch } from 'vue';

export default {
  props: {
    header: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['sort'],
  /* eslint-disable no-unused-vars */
  setup(props, { emit }) {
    const headerClasses = inject('headerClasses');
    const sort = inject('sort');
    const order = ref('');

    const bindProps = computed(() => {
      const { label, name, sortAction, ...obj } = props.header;
      return obj;
    });

    watch(
      () => sort.value,
      (val) => {
        if (val.name !== props.header.name) {
          order.value = '';
        }
      }
    );

    function handleSort() {
      if (props.header.sortAction) {
        const { name } = props.header;
        const $order = getOrder();
        order.value = $order;
        const item = { name, order: $order };
        props.header.sortAction(item);
        emit('sort', item);
      }
    }

    function getOrder() {
      if (order.value === '') {
        return 'desc';
      }
      if (order.value === 'desc') {
        return 'asc';
      }
      return '';
    }

    return {
      headerClasses,
      order,
      bindProps,
      handleSort,
    };
  },
};
</script>

<style lang="postcss">
th {
  @apply bg-white
    text-gray-500
    font-poppins
    font-normal
    rounded-t-md
    py-4
    px-6
    text-left;
}
.sort-button {
  @apply transition-all
    duration-300
    ease-out
    text-gray-500
    opacity-50;
}
.sort-button.active {
  @apply text-blue-500 opacity-100;
}
</style>
