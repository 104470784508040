<template>
  <div
    v-click-outside="handleClose"
    class="search-dropdown"
    :class="{ append: hasAppendSlot }"
  >
    <div class="relative">
      <input
        type="text"
        class="input"
        :class="{ append: hasAppendSlot }"
        :placeholder="placeholder"
        v-model="search"
        @input="handleChange"
        @click="handleOpen"
        @focus="handleOpen"
        @blur="handleClose"
        @keyup.esc="handleClose"
      />
      <span @click="handleResetSearch" v-if="search" class="icon"
        ><close-icon class="text-gray-500 w-3 h-3 stroke-2"
      /></span>
      <span @click="handleToggle" v-else class="icon"
        ><chevron-down-icon class="text-gray-500 w-3 h-3 stroke-2"
      /></span>
      <transition name="fade">
        <div class="search-dropdown-items" v-show="open">
          <div class="search-dropdown-scroll">
            <SearchDropdownItem
              v-for="item in searchResults"
              :key="item[indexKey]"
              :item="item"
              :index="findIndex(item[indexKey])"
              :cursor-index="cursorIndex"
              :display-key="displayKey"
              @select="handleSelectOnClick"
            />
          </div>
        </div>
      </transition>
    </div>
    <span v-if="hasAppendSlot" class="append-slot">
      <slot name="append" />
    </span>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import Fuse from 'fuse.js';
import SearchDropdownItem from './SearchDropdownItem.vue';
import { useState } from '@/helpers/composables';

export default {
  components: {
    SearchDropdownItem,
  },
  props: {
    placeholder: {
      type: String,
      default: () => '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    displayKey: {
      type: String,
      default: () => 'id',
      required: true,
    },
    indexKey: {
      type: String,
      default: () => 'id',
    },
    searchKeys: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: () => '',
    },
  },
  emits: ['select'],
  setup(props, { emit, slots }) {
    const fuse = new Fuse(props.items, {
      keys: props.searchKeys,
    });

    const hasAppendSlot = computed(() => slots.append);
    const searchResults = ref(props.items);
    const search = ref('');
    const cursorIndex = ref(-1);
    const [open, setOpen] = useState(false);

    watch(
      () => props.items,
      (value) => {
        searchResults.value = value;
        fuse.setCollection(value);
      }
    );

    function handleToggle() {
      setOpen(!open.value);
    }

    function handleOpen() {
      setOpen(true);
    }

    function handleClose() {
      setOpen(false);
    }

    function handleSelectOnClick({ value, item, index }) {
      const { name } = props;
      emit('select', { name, value, index, item });
      search.value = value;
      cursorIndex.value = index;
      handleClose();
    }

    function handleChange(event) {
      const { value } = event.target;
      if (value) {
        handleOpen();
        return handleSearch(value);
      }
      resetResults();
    }

    function handleSearch(value) {
      const results = fuse.search(value);
      searchResults.value = results.map(({ item }) => item);
    }

    function handleResetSearch() {
      const { name } = props;
      resetResults();
      search.value = '';
      cursorIndex.value = -1;
      emit('select', { name, value: search.value });
    }

    function resetResults() {
      searchResults.value = props.items;
    }

    function findIndex(id) {
      return searchResults.value.findIndex((r) => r[props.indexKey] === id);
    }

    // function handleSelectOnEnter() {
    //   const item = searchResults.value.find((r, i) => i === cursorIndex.value);
    //   const value = item[props.displayKey];
    //   emit('select', { value, index: cursorIndex.value, item });
    //   search.value = value;
    //   handleClose();
    // }

    // function handleTraverseDown() {
    //   handleOpen();
    //   if(cursorIndex.value < searchResults.value.length - 1) {
    //     cursorIndex.value ++;
    //   }
    // }

    // function handleTraverseUp() {
    //   handleOpen();
    //   if(cursorIndex.value > 0) {
    //     cursorIndex.value --;
    //   }
    // }

    return {
      handleToggle,
      handleOpen,
      handleClose,
      handleChange,
      handleSelectOnClick,
      cursorIndex,
      open,
      search,
      searchResults,
      findIndex,
      handleResetSearch,
      hasAppendSlot,
      // handleTraverseDown,
      // handleTraverseUp,
      // handleSelectOnEnter
    };
  },
};
</script>

<style lang="postcss" scoped>
.search-dropdown.append {
  @apply flex;
}
.search-dropdown .append-slot {
  @apply flex;
}
.input {
  @apply appearance-none
      block
      w-full
      px-3
      py-2
      border 
      rounded-md
      border-gray-300
      placeholder-gray-500
      focus:outline-none 
      focus:ring-yellow-500
      focus:border-yellow-500;
}
.input.append {
  @apply rounded-r-none;
}
.search-dropdown-scroll {
  @apply overflow-y-auto max-h-64;
}
.search-dropdown-items {
  @apply absolute
    top-full
    w-full
    bg-white
    rounded
    shadow-md
    mt-1
    z-10
    border
    border-gray-300
    overflow-hidden;
}
.icon {
  @apply absolute
    right-3
    top-1/2
    transform
    -translate-y-1/2
    cursor-pointer;
}
</style>
