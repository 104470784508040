<template>
  <td v-bind="bindProps" :class="columnClasses">
    <component
      :is="column.component"
      v-if="renderFromComponent"
      v-bind="componentProps"
    >
      {{ render }}
    </component>
    <span v-else-if="renderFromFunction">
      {{ render }}
    </span>
    <span v-else>
      {{ value }}
    </span>
  </td>
</template>

<script>
import { computed, inject } from 'vue';

export default {
  props: {
    column: {
      type: Object,
      default: () => ({}),
    },
    row: {
      type: Object,
      default: () => ({}),
    },
  },
  /* eslint-disable no-unused-vars */
  setup(props) {
    const columnClasses = inject('columnClasses');
    const value = computed(() => props.row[props.column.name]);

    const render = computed(() => {
      return (
        props.column.render &&
        props.column.render({
          row: props.row,
          column: props.column,
          name: props.column.name,
          data: value,
        })
      );
    });

    const dynamicProps = computed(() => {
      return (
        props.column.$props &&
        props.column.$props({
          row: props.row,
          column: props.column,
          name: props.column.name,
          data: value,
        })
      );
    });

    const bindProps = computed(() => {
      const { name, render, $props, to, component, ...obj } = props.column;
      return obj;
    });

    const componentProps = computed(() => {
      const { name, render, component, ...obj } = props.column;
      return dynamicProps.value ? dynamicProps.value : obj;
    });

    const renderIsFunction = computed(() => {
      return typeof props.column.render === 'function';
    });

    const renderFromComponent = computed(
      () => renderIsFunction.value && props.column.component
    );

    const renderFromFunction = computed(
      () => renderIsFunction.value && !props.column.component
    );

    return {
      columnClasses,
      value,
      render,
      bindProps,
      componentProps,
      renderFromComponent,
      renderFromFunction,
    };
  },
};
</script>

<style lang="postcss">
td {
  @apply py-4
    px-6;
}
</style>
